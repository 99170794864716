import React, { useState } from "react";
import das from "../../assets/images/home.png";
import { UploadM } from "../uploadModal/uploadM";
import { UploadModal } from "../modal/modal";
import { toast } from "react-toastify";

export const Banner = ({ handleTrySubscriptuon, pricingRef }) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const getCookie = (name1, name2) => {
    const array = [name1, name2];
    console.log(document.cookie, "===============");
    if (document.cookie) {
      const value = `${document.cookie}`;
      const parts = value.split(` `).flatMap((item) => {
        const key = item.split("=")[0];
        const value = item.split("=")[1];
        return { [key]: value.replace(";", "") };
      });
      const searchedParams = parts.filter((item) =>
        array.includes(Object.keys(item)[0])
      );
      let combinedObject = {};
      searchedParams.forEach((acc) => {
        combinedObject = { ...combinedObject, ...acc };
      });

      return combinedObject;
    }
    return { eligible: "true", subscription: "false" };
  };

  const handleUpload = () => {
    const { eligible } = getCookie("eligible");
    if (eligible !== "false") {
      openModal();
    } else {
      handleTrySubscriptuon(pricingRef);
      toast.error("You have already used once");
    }
  };

  const tokenn = localStorage.getItem("token");

  return (
    <>
      <UploadM
        isOpen={showModal}
        onClose={closeModal}
        pricingRef={pricingRef}
        handleTrySubscriptuon={handleTrySubscriptuon}
      />
      {/* {tokenn ? (
      ) : (
        <UploadModal isOpen={showModal} onClose={closeModal} />
      )} */}
      <section className="automate">
        <div className="container">
          <div className="row center-custom">
            <div className="col-lg-6">
              <div className="automate-left">
                <p className="Writer-1">Welcome to [Your Real Estate Agency]</p>
                <p className="Writer-2">Discover Your Perfect Home</p>
                <p className="Writer-3">
                  Explore a diverse range of properties in the most sought-after
                  neighborhoods. Whether you're searching for a cozy starter
                  home or an elegant estate, we have the keys to your ideal
                  property..
                </p>
                <p className="Writer-4">Start your 7-day free trial today</p>
                <ul className="writer-ul ">
                  <li>
                    <button className="Automate-1 BT" onClick={handleUpload}>
                      {" "}
                      Upload Your Image{" "}
                    </button>
                    {/* <ul className="writer-ul">
                      <li>
                        <button className="Automate BT">
                          Automate Your Blog
                        </button>
                      </li>
                      <li>
                        <button className="Automate-1 BT">
                          Create a Demo Article
                        </button>
                      </li>
                    </ul> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="automate-right">
                <img src={das} className="das-img" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
