import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";
import { BASE_URL } from "../../common/common";

export const UploadM = ({
  isOpen,
  onClose,
  children,
  handleTrySubscriptuon,
  pricingRef,
}) => {
  const [file, setFile] = useState("");
  const [response, setResponse] = useState("");
  const [responseData, setResponseData] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let pollingInterval;

  const getCookie = (name1, name2) => {
    const array = [name1, name2];
    console.log(document.cookie, "===============");
    if (document.cookie) {
      const value = `${document.cookie}`;
      const parts = value.split(` `).flatMap((item) => {
        const key = item.split("=")[0];
        const value = item.split("=")[1];
        return { [key]: value.replace(";", "") };
      });
      const searchedParams = parts.filter((item) =>
        array.includes(Object.keys(item)[0])
      );
      let combinedObject = {};
      searchedParams.forEach((acc) => {
        combinedObject = { ...combinedObject, ...acc };
      });

      return combinedObject;
    }
    return { eligible: "true", subscription: "false" };
  };

  const handleUpload = () => {
    const { eligible } = getCookie("eligible");
    if (eligible !== "false") {
      const formData = new FormData();
      formData.append("file", file);
      setLoading(true);

      axios
        .post(`${BASE_URL}/files/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setResponse(response?.data);
          localStorage.setItem("Upload_Id", response?.data?.id);
          pollingInterval = setInterval(GetCaptionData, 10000);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail?.[0]?.msg);
          setLoading(false);
          console.error("Error:", error);
        });
    } else {
      setMessage("you are not Eligible to use this again!");
      toast.error("You have already used once");
    }
  };

  const GetCaptionData = () => {
    const id = localStorage.getItem("Upload_Id");
    axios
      .get(`${BASE_URL}/files/${id}/`)
      .then((response) => {
        if (response?.data?.status !== "completed") {
          setLoading(true);
          setResponseData(response?.data);
        } else {
          clearInterval(pollingInterval);
          setLoading(false);
          setResponseData(response?.data);
          const { eligible, subscription } = getCookie(
            "eligible",
            "subscription"
          );
          setMessage("");
          if (eligible === "true" && subscription === "false") {
            let expires = "";
            const date = new Date();
            date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toGMTString();
            document.cookie = "eligible=false" + expires + "; path=/";
            document.cookie = "subscription=false" + expires + "; path=/";
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (!isOpen) return null;

  const modalClass = isOpen ? "modal open" : "modal";

  const handleClose = () => {
    setResponse();
    setResponseData();
    setFile();
    onClose();
  };

  const handleClickSubscription = () => {
    onClose();
    handleTrySubscriptuon(pricingRef);
  };

  return (
    <>
      <div className={modalClass}>
        {loading && <Loader responseData={responseData} />}
        {children}
        <div className="sm:max-w-lg w-full p-10 bg-white rounded-xl z-10 mx-auto mb-2 mt-3">
          <div className="flex justify-items-end	">
            <button
              type="button"
              className="text-gray-400 bg-transparent text-right hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="defaultModal"
              onClick={handleClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="text-center">
            <h2 className="mt-2 text-3xl font-bold text-gray-900">
              File Upload!
            </h2>
          </div>
          <form className="mt-8 space-y-3">
            {response?.file_url ? (
              <img
                src={response?.file_url}
                alt="Selected"
                style={{ maxWidth: "400px" }}
                className="mx-auto"
              />
            ) : (
              <div className="grid grid-cols-1 space-y-2">
                {file ? (
                  <img
                    className="mx-auto"
                    src={URL.createObjectURL(file)}
                    alt=""
                  />
                ) : (
                  <>
                    <label className="text-sm font-bold text-gray-500 tracking-wide ">
                      Attach Document
                    </label>
                    <div className="flex items-center justify-center w-full">
                      <label className="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center">
                        <div className="h-full w-full text-center flex flex-col items-center justify-center items-center">
                          <div className="flex flex-auto max-h-48 w-2/5 mx-auto -mt-10">
                            <img
                              className="has-mask h-36 object-center"
                              src="https://img.freepik.com/free-vector/image-upload-concept-landing-page_52683-27130.jpg?size=338&ext=jpg"
                              alt="freepik image"
                            />
                          </div>
                          <p className="pointer-none text-gray-500">
                            <span className="text-sm">Drag and drop</span> files
                            here <br /> or select a file from your computer
                          </p>
                        </div>

                        <input
                          type="file"
                          className="hidden"
                          name="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          required
                        />
                      </label>
                    </div>{" "}
                  </>
                )}
              </div>
            )}

            {responseData?.detailed_caption ? (
              <p className="text-md text-black font-bold">
                Long Caption:
                <span className="text-md text-red-500">
                  {responseData?.detailed_caption}
                </span>
              </p>
            ) : (
              <p className="text-sm text-gray-300">
                <span>File type: doc, pdf, types of images</span>
              </p>
            )}

            {/* {responseData?.caption ? (
              <p className="text-md text-black font-bold">
                Short Caption :
                <span className="text-md text-red-500">
                  {responseData?.caption}
                </span>
              </p>
            ) : (
              ""
            )} */}

            {!responseData?.caption ? (
              <div>
                <button
                  type="button"
                  className="my-5 w-full flex justify-center bg-blue-500 text-gray-100 p-4 rounded-full tracking-wide font-semibold focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300"
                  onClick={handleUpload}
                >
                  Upload
                </button>
                {message && (
                  <>
                    <p>{message}</p>
                    <p>
                      Click{" "}
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => handleClickSubscription()}
                      >
                        Here
                      </span>{" "}
                      to try Subscription
                    </p>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </>
  );
};
