import React from "react";

export const Language = () => {
  return (
    <>
      <section className="spoted-lang">
        <div className="container">
          <div className="row al-cen">
            <div className="col-lg-4">
              <div className="spoted-left">
                <p className="spoted-p">
                  Supported <br /> Languages
                </p>
                <p className="spoted-p1">
                  We support 50 different languages and continuously work on
                  expanding this list.
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="spoted-right">
                <ul>
                  <li>
                    <a href="#">Arabic</a>
                    <a href="#">Basque</a>
                    <a href="#">Bengali</a>
                    <a href="#">Bulgarian</a>
                    <a href="#">Catalan</a>
                    <a href="#">Chinese</a>
                    <a href="#">Croatian</a>
                    <a href="#">Czech</a>
                    <a href="#">Danish</a>
                    <a href="#">Dutch 🇱</a>
                    <a href="#">English🇸</a>
                    <a href="#">Estonian 🇪</a>
                    <a href="#">Finnish 🇫</a>
                  </li>
                  <li>
                    <a href="#">French</a>
                    <a href="#">Galician</a>
                    <a href="#">German</a>
                    <a href="#">Gujarati</a>
                    <a href="#">Hebrew🇱</a>
                    <a href="#">Hindi</a>
                    <a href="#">Hungarian</a>
                    <a href="#">Icelandic</a>
                    <a href="#">Italian</a>
                    <a href="#">Japanese</a>
                    <a href="#">Kannada</a>
                    <a href="#">Korean</a>
                    <a href="#">Latvian</a>
                  </li>
                  <li>
                    <a href="#">Lithuanian</a>
                    <a href="#">Malay 🇳</a>
                    <a href="#">Malayalam</a>
                    <a href="#">Marathi</a>
                    <a href="#">Norwegian</a>
                    <a href="#">Polish</a>
                    <a href="#">Portuguese</a>
                    <a href="#">Punjabi</a>
                    <a href="#">Romanian</a>
                    <a href="#">Russian🇺</a>
                    <a href="#">Serbian🇸</a>
                    <a href="#">Slovak🇰</a>
                    <a href="#">Slovenian</a>
                  </li>
                  <li>
                    <a href="#">Spanish</a>
                    <a href="#">Swedish🇪</a>
                    <a href="#">Tamil</a>
                    <a href="#">Telugu</a>
                    <a href="#">Thai🇭</a>
                    <a href="#">Turkish</a>
                    <a href="#">Ukrainian</a>
                    <a href="#">Vietnamese</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
